<template>
  <div
    class="
        container-body__content
        w-10/12
        scrollbar
        md:rounded-2xl
        m-auto
        flex
        justify-center
        items-center
      "
  >
    <div class="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="78"
        height="62"
        viewBox="0 0 78 62"
        fill="none"
        class="mx-auto"
      >
        <path
          d="M4 34.9701L28.6429 54L73 4"
          stroke="#6A30C3"
          stroke-width="11"
        />
        <path
          d="M4 34.9701L28.6429 54L73 4"
          stroke="url(#paint0_linear_21_2290)"
          stroke-width="11"
        />
        <defs>
          <linearGradient
            id="paint0_linear_21_2290"
            x1="-427.857"
            y1="244"
            x2="185.813"
            y2="510.308"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.38059" stop-color="#C6003B" />
            <stop offset="0.491928" stop-color="#6A30C3" />
            <stop offset="0.600732" stop-color="#63DFDF" />
          </linearGradient>
        </defs>
      </svg>
      <h3 class="text-2xl font-bold mt-10 text-brandPurple">
        Refund <br />complete
      </h3>

      <div class="spam-notice my-6 py-6 rounded-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#0EA2F5"
          />
        </svg>
        <p class="text-sm text-left text-brandGrey-1 mr-6">
          Note: Your refund has been processed and should reflect in your customer's wallet within 24hrs.
        </p>
      </div>

      <div class="mt-10 w-full">
        <Button text="Close" width="w-full" outline @click="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/UI/Button";
export default {
  components: {
    Button
  }
};
</script>

<style>
</style>